import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment/moment'

export const initialState = null

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, { payload }) => {
            if (payload) {
                payload.now = moment().add(10, 'minute').format('YYYY-MM-DD HH:mm:ss')
            }
            return payload
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser, userLoggedOut } = userSlice.actions

export default userSlice.reducer
