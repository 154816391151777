import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { Lazy } from 'Components/Loader'
import auth from './auth'
import admin from './admin'
import user from './user'

export default function Router() {
    return useRoutes([
        { path: '/', element: <Navigate to="/login" replace /> },
        ...auth,
        ...admin,
        ...user,
        { path: 'error', element: <Lazy view={'Error'} /> },
        { path: '*', element: <Navigate to="/error" replace /> },
    ])
}
