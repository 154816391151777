import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment/moment'
import { apiMonthlyList } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/monthly/getData', async datas => {
    const { data } = await apiMonthlyList(datas)
    return data
})

export const monthlySlice = createSlice({
    name: 'admin/report/monthly',
    initialState: {
        loading: true,
        data: [],
        date: {
            start: moment().startOf('month').locale('en').format('YYYY-MM-DD'),
            end: moment().endOf('month').locale('en').format('YYYY-MM-DD'),
        },
    },
    reducers: {
        setDatas: (state, { payload }) => {
            state.date = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
            })
    },
})

export const { setDatas } = monthlySlice.actions

export default monthlySlice.reducer
