import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ title, list = [] }) => {
    const { user } = useSelector(state => state.auth)
    var BList = [
        {
            text: 'Dashboard',
        },
    ]
    BList.push(...list)
    if (!user || !user.guard) return
    return (
        <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
            <h2 className="text-2xl font-semibold text-black dark:text-white">{title}</h2>
            <nav>
                <ol className="flex items-center gap-1">
                    {BList.map((item, index) => (
                        <li key={index}>
                            <Link
                                className="text-sm text-black dark:text-white"
                                to={`/${user.guard}${item.link ? `/${item.link}` : ''}`}
                            >
                                {item.text} /
                            </Link>
                        </li>
                    ))}
                    <li className="text-sm text-primary dark:text-secondary">{title}</li>
                </ol>
            </nav>
        </div>
    )
}

export default Breadcrumb
