import { Field } from 'formik'
import { useState } from 'react'
import { IconContext } from 'react-icons'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

export default function TextInput({
    type = 'text',
    id = '',
    label = '',
    placeholder = '',
    className = '',
    icon = '',
    error = '',
    leftSpan = false,
    password = false,
    currency = false,
    ...props
}) {
    const [secureText, setSecureText] = useState(password)
    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div className="relative flex items-stretch flex-nowrap">
                {leftSpan && (
                    <span className="flex items-center px-3 text-black border border-r-0 rounded-lg rounded-r-none border-stroke bg-gray dark:bg-graydark dark:text-white dark:border-graydark">
                        {leftSpan}
                    </span>
                )}
                <Field
                    {...props}
                    type={secureText ? 'password' : type}
                    id={id}
                    placeholder={label ? label : placeholder}
                    className={`${leftSpan && 'rounded-l-none'} w-full flex-auto rounded-lg border bg-transparent py-2 pl-3 ${
                        (password || icon || currency) && 'pr-9'
                    } ${error ? 'border-danger focus:ring-danger focus:border-danger' : 'border-stroke focus:ring-primary focus:border-primary dark:border-strokedark dark:bg-strokedark dark:focus:ring-secondary dark:focus:border-secondary'} outline-none disabled:cursor-not-allowed disabled:bg-gray dark:disabled:bg-graydark`}
                />
                {password && (
                    <span className="absolute -translate-y-1/2 top-1/2 right-4" onClick={() => setSecureText(e => !e)}>
                        <IconContext.Provider value={{ size: '22' }}>
                            {secureText ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </IconContext.Provider>
                    </span>
                )}
                {(icon || currency) && (
                    <span className="absolute -translate-y-1/2 top-1/2 right-4">
                        <IconContext.Provider value={{ color: '#c4cad3', size: '22' }}>
                            {currency ? currency : icon}
                        </IconContext.Provider>
                    </span>
                )}
            </div>
            {error && <p className="text-sm text-danger">{error}</p>}
        </div>
    )
}
