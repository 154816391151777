import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        token_expires_at: '',
        refresh_token: '',
        refresh_token_expires_at: '',
        signedIn: false,
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload.token
            state.token_expires_at = action.payload.token_expires_at
            state.refresh_token = action.payload.refresh_token
            state.refresh_token_expires_at = action.payload.refresh_token_expires_at
        },
        onSignOutSuccess: state => {
            state.signedIn = false
            state.token = ''
            state.token_expires_at = ''
            state.refresh_token = ''
            state.refresh_token_expires_at = ''
        },
        setToken: (state, action) => {
            state.token = action.payload.token
            state.token_expires_at = action.payload.token_expires_at
        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken } = sessionSlice.actions

export default sessionSlice.reducer
