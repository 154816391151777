import { Outlet } from 'react-router-dom'

import { Auth, User } from 'Components/Layout'
import { Lazy } from 'Components/Loader'

import Password from 'Components/FormGroup/Password'

const user = [
    {
        path: 'user',
        element: <Auth guard="user" />,
        children: [
            { path: 'verify-email', element: <Lazy view={'Auth/VerifyEmail'} /> },
            { path: 'verify-email/:id/:hash', element: <Lazy view={'Auth/VerifyEmail'} /> },
        ],
    },
    {
        path: 'user',
        element: <User guard="user" />,
        children: [
            { path: '', element: <Lazy view={'User/Dashboard'} /> },
            { path: 'profile', element: <Lazy view={'User/Dashboard/Profile'} /> },
            { path: 'password', element: <Password /> },
            {
                path: 'cages',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/Cages/List'} /> },
                    // { path: 'create', element: <Lazy view={'User/Cages/Create'} title="Add new Box" /> },
                    // { path: ':id/edit', element: <Lazy view={'User/Cages/Create'} title="Edit" /> },
                    { path: ':id', element: <Lazy view={'User/Cages/Show'} title="View" /> },
                ],
            },
            {
                path: 'pickups',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/Pickups/List'} /> },
                    { path: ':id', element: <Lazy view={'User/Pickups/Show'} title="View" /> },
                ],
            },
            {
                path: 'file-uploads',
                element: <Outlet />,
                children: [{ path: '', element: <Lazy view={'User/FileUploads/List'} /> }],
            },
            {
                path: 'contacts',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/Contacts/List'} /> },
                    { path: 'create', element: <Lazy view={'User/Contacts/Create'} title="Contact admin" /> },
                    { path: ':id', element: <Lazy view={'User/Contacts/Show'} title="View" /> },
                ],
            },
        ],
    },
    // Collection Points
    {
        path: 'user',
        element: <User guard="user" type="0" />,
        children: [
            {
                path: 'collector-collection-points',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/CollectorCollectionPoints/List'} /> },
                    {
                        path: 'create',
                        element: <Lazy view={'User/CollectorCollectionPoints/Create'} title="Link New Collector" />,
                    },
                ],
            },
            {
                path: 'projects',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/Projects/List'} /> },
                    { path: 'create', element: <Lazy view={'User/Projects/Create'} title="Add new Project" /> },
                    { path: ':id/edit', element: <Lazy view={'User/Projects/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'waste-drops',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/WasteDrops/List'} /> },
                    { path: 'create', element: <Lazy view={'User/WasteDrops/Create'} title="Waste Bag Drops" /> },
                    { path: ':id/edit', element: <Lazy view={'User/WasteDrops/Create'} title="Edit" /> },
                    { path: ':id', element: <Lazy view={'User/WasteDrops/Show'} title="View" /> },
                ],
            },
            {
                path: 'bulko-bags',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/BulkoBags/List'} /> },
                    {
                        path: 'create',
                        element: <Lazy view={'User/BulkoBags/Create'} title="Generate QR tag for Bulka Bag Bin" />,
                    },
                    { path: ':id/edit', element: <Lazy view={'User/BulkoBags/Create'} title="Edit" /> },
                    { path: ':id', element: <Lazy view={'User/BulkoBags/Show'} title="View" /> },
                ],
            },
            {
                path: 'pickups',
                element: <Outlet />,
                children: [
                    { path: 'create', element: <Lazy view={'User/Pickups/Create'} title="Book pick up" /> },
                    { path: ':id', element: <Lazy view={'User/Pickups/Show'} title="View" /> },
                ],
            },
            {
                path: 'collector-bag-requests',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/CollectorBagRequests/List'} /> },
                    {
                        path: 'create',
                        element: <Lazy view={'User/CollectorBagRequests/Create'} title="Requested Bags for a Collector" />,
                    },
                    { path: ':id/edit', element: <Lazy view={'User/CollectorBagRequests/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'bag-requests',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/CollectionPointBagRequests/List'} /> },
                    {
                        path: 'create',
                        element: <Lazy view={'User/CollectionPointBagRequests/Create'} title="Request Bags or Bins" />,
                    },
                    { path: ':id/edit', element: <Lazy view={'User/CollectionPointBagRequests/Create'} title="Edit" /> },
                ],
            },
        ],
    },
    // Affiliates
    {
        path: 'user',
        element: <User guard="user" type="1" />,
        children: [
            {
                path: 'batches',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'User/Batches/List'} /> },
                    { path: 'create', element: <Lazy view={'User/Batches/Create'} title="Add new Batch" /> },
                    // { path: ':id/edit', element: <Lazy view={'User/Batches/Create'} title="Edit" /> },
                    { path: ':id', element: <Lazy view={'User/Batches/Show'} title="View" /> },
                ],
            },
        ],
    },
]

export default user
