import { useSelector } from 'react-redux'
import { AiOutlineLogout, AiOutlineUnlock, AiOutlineUser } from 'react-icons/ai'

import { Dropdown } from 'Components/Ui'
import useAuth from 'Utils/Hooks/useAuth'

const DropdownUser = () => {
    const { user } = useSelector(state => state.auth)
    const { signOut } = useAuth()
    if (!user) return
    return (
        <>
            <Dropdown>
                <Dropdown.Trigger>
                    <span className="hidden text-right lg:block">
                        <span className="block text-sm font-medium text-black dark:text-white">{user.name}</span>
                        <span className="block text-xs">{user.email}</span>
                    </span>

                    <span className="w-10 h-10 pl-1 rounded-full">
                        <img src={user.image_url + '?h=40&w=40&aspect=true&type=webp'} alt="User" />
                    </span>
                </Dropdown.Trigger>
                <Dropdown.Content width="60">
                    <Dropdown.Link to="/user/profile">
                        <AiOutlineUser size={23} />
                        My Profile
                    </Dropdown.Link>
                    <Dropdown.Link to="/user/password">
                        <AiOutlineUnlock size={23} />
                        Change Password
                    </Dropdown.Link>
                    <Dropdown.Link className="border-t border-stroke dark:border-strokedark" onClick={signOut}>
                        <AiOutlineLogout size={23} />
                        Log Out
                    </Dropdown.Link>
                </Dropdown.Content>
            </Dropdown>
        </>
    )
}

export default DropdownUser
