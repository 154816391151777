import { clone, remove } from 'lodash'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'

import { Button, Select, TextInput } from 'Components/Form'

export const dataDefault = {
    waste_drop_material_type_id: '',
    bulko_bag_id: '',
    bags_count: '',
}

export const setDefaultDate = (material_types, bulko_bag, bulko_bags) => {
    var defaultBulkoBag = []
    material_types.map(e => {
        var clones = clone(dataDefault)
        clones.waste_drop_material_type_id = e.id
        clones.bags_count = e.accept_bags
        clones.bulko_bag_id = bulko_bags.length === 1 ? bulko_bags[0].id : ''
        defaultBulkoBag.push(clones)
        return e
    })
    return bulko_bag && bulko_bag.length !== 0 ? bulko_bag : defaultBulkoBag
}

export default function BulkoBag({ index, datas, data, handleChange, touched, errors, material_types, bulko_bags }) {
    var name = `bulko_bag[${index}].`

    const setDate = (name, value) => {
        var saveTask = clone(datas)
        saveTask = saveTask.map((e, i) => {
            var val = clone(e)
            if (i === index) {
                val[name] = value
            }
            return val
        })
        handleChange('bulko_bag', saveTask)
    }

    return (
        <div className="flex flex-col gap-3">
            <div className="grid grid-cols-3 gap-3 sm:grid-cols-7">
                <Select
                    id={`${name}waste_drop_material_type_id`}
                    name={`${name}waste_drop_material_type_id`}
                    label={index === 0 ? 'Waste Drop' : null}
                    placeholder="Waste Drop"
                    value={data.waste_drop_material_type_id}
                    options={material_types.map(e => ({
                        id: e.id,
                        label: (e.material?.title ?? '') + ' (' + e.accept_bags + ')',
                    }))}
                    onChange={e => setDate(`waste_drop_material_type_id`, e.target.value)}
                    error={touched?.waste_drop_material_type_id && errors?.waste_drop_material_type_id}
                    className="col-span-3 sm:col-span-2"
                />
                <Select
                    id={`${name}bulko_bag_id`}
                    name={`${name}bulko_bag_id`}
                    label={index === 0 ? 'ResiLoop Bin' : null}
                    placeholder="ResiLoop Bin"
                    value={data.bulko_bag_id}
                    options={bulko_bags.map(e => ({
                        id: e.id,
                        label: e.number,
                    }))}
                    onChange={e => setDate(`bulko_bag_id`, e.target.value)}
                    error={touched?.bulko_bag_id && errors?.bulko_bag_id}
                    className="col-span-3 sm:col-span-2"
                />
                <TextInput
                    type="number"
                    id={`${name}bags_count`}
                    name={`${name}bags_count`}
                    label={index === 0 ? 'Number of Bags' : null}
                    placeholder="Number of Bags"
                    value={data.bags_count}
                    onChange={e => setDate(`bags_count`, e.target.value)}
                    error={touched?.bags_count && errors?.bags_count}
                    min="1"
                    className="col-span-2 sm:col-span-2"
                />
                <div>
                    <div className={`flex items-center gap-3 ${index === 0 ? 'pt-7' : null}`}>
                        {index + 1 === datas.length ? (
                            <Button
                                type="button"
                                className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                onClick={() => {
                                    var saveTask = clone(datas)
                                    saveTask.push(clone(dataDefault))
                                    handleChange('bulko_bag', saveTask)
                                }}
                            >
                                <AiOutlinePlus size={20} />
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                className="text-white border-danger bg-danger"
                                onClick={() => {
                                    var saveTask = clone(datas)
                                    remove(saveTask, (n, key) => key === index)
                                    handleChange('bulko_bag', saveTask)
                                }}
                            >
                                <AiOutlineClose size={20} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
