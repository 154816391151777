import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import moment from 'moment/moment'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { DateRangePicker as DatePicker } from 'react-date-range'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { Cart } from '../Ui'
import Button from './Button'

export default function DateRangePicker({
    id = '',
    label = '',
    className = '',
    panelClassName = '',
    error = '',
    startDate,
    endDate,
    saveDate,
    ...props
}) {
    const [date, setDate] = useState()

    const resetDate = () => {
        setDate({
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            key: 'selection',
        })
    }
    useEffect(() => {
        resetDate()
        // eslint-disable-next-line
    }, [startDate, endDate])

    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <Popover className="relative">
                {({ close }) => (
                    <>
                        <Popover.Button
                            className={`flex flex-row items-center gap-2 custom-input-date w-full flex-auto rounded-lg border border-stroke bg-transparent py-2 px-3 outline-none focus:border-primary dark:border-strokedark dark:bg-strokedark dark:focus:border-secondary`}
                        >
                            <FaRegCalendarAlt /> {moment(startDate).format('ll')} - {moment(endDate).format('ll')}
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className={`absolute ${panelClassName} z-10`}>
                                <Cart divclass="">
                                    <DatePicker id={id} {...props} ranges={[date]} onChange={e => setDate(e.selection)} />
                                    <div className="flex items-center gap-4 p-3">
                                        <Button
                                            type="button"
                                            className="w-full text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            onClick={() => {
                                                close()
                                                saveDate(
                                                    moment(date.startDate).locale('en').format('YYYY-MM-DD'),
                                                    moment(date.endDate).locale('en').format('YYYY-MM-DD')
                                                )
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            type="button"
                                            className="w-full text-white border-danger bg-danger"
                                            onClick={() => {
                                                close()
                                                resetDate()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Cart>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
            {error && <p className="text-sm text-danger">{error}</p>}
        </div>
    )
}
