import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { apiError, authorization } from 'Utils/alerts'
const { REACT_APP_URL } = process.env

const BaseService = axios.create({
    timeout: 60000,
    baseURL: REACT_APP_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    },
})

export function useAxiosLoader() {
    const [count, setCount] = useState(0)
    const interceptors = useMemo(() => {
        const inc = () => setCount(count => count + 1)
        const dec = () => setCount(count => count - 1)
        return {
            request: request => {
                inc()
                authorization(request)
                return request
            },
            response: response => {
                dec()
                return response
            },
            error: error => {
                dec()
                apiError(error)
                return Promise.reject(error)
            },
        }
    }, [])
    useEffect(() => {
        BaseService.interceptors.request.use(interceptors.request, interceptors.error)
        BaseService.interceptors.response.use(interceptors.response, interceptors.error)
    }, [interceptors])
    return count > 0
}

export default BaseService
