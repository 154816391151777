import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment/moment'
import { apiProjectList, apiProjectView } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/project/getData', async datas => {
    const { data } = await apiProjectList(datas)
    return data
})

export const getView = createAsyncThunk('admin/project/getView', async id => {
    if (!id) return null
    const { data } = await apiProjectView(id)
    return data
})

export const projectSlice = createSlice({
    name: 'admin/report/project',
    initialState: {
        loading: true,
        view: null,
        data: [],
        total: 0,
        table: {
            start: moment().startOf('month').locale('en').format('YYYY-MM-DD'),
            end: moment().endOf('month').locale('en').format('YYYY-MM-DD'),
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
        setDate: (state, { payload: { start, end } }) => {
            state.table.start = start
            state.table.end = end
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = null
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData, setDate } = projectSlice.actions

export default projectSlice.reducer
