import React from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { BiSearchAlt } from 'react-icons/bi'
import { AiFillCaretDown, AiFillCaretUp, AiOutlinePlus } from 'react-icons/ai'

import { Button, Checkbox, Select, TextInput } from 'Components/Form'
import Cart from './Cart'
import { downloadType } from 'Utils/downloadFile'

export const defoltclassName = 'p-2 md:px-4 2xl:px-6'

const Table = ({
    columns,
    loading,
    datas,
    total,
    table,
    onChange,
    add_new,
    apiExport,
    search_show = true,
    select = false,
    ids,
    setIds,
    grid = 'sm:flex-row sm:justify-between',
    subGrid = 'sm:flex-row sm:justify-between',
    children,
}) => {
    const { length, page, column, dir, search } = table

    var totalPage = Math.ceil(total / length)
    var pages = []
    const from = length * (page - 1) + 1
    const to = total > length * page ? length * page : total

    for (let i = 1; i <= totalPage; i++) {
        if (i === 1) {
            pages.push('Previous')
            pages.push(i)
        } else if (i === totalPage) {
            pages.push(i)
            pages.push('Next')
        } else {
            pages.push(i)
        }
    }

    const ColumnTd = ({ row, column }) => {
        return (
            <td
                className={`border-b border-stroke dark:border-strokedark items-center text-sm text-black dark:text-white ${defoltclassName} ${column.className}`}
            >
                {column.renderCell ? column.renderCell(row) : row[column.field]}
            </td>
        )
    }
    const gridClass = `flex flex-col items-center gap-3 ${grid}`
    const subGridClass = `flex flex-col items-center gap-3 ${subGrid}`

    const onPaginationChange = page => {
        const newTable = { ...table }
        newTable.page = page
        onChange(newTable)
    }

    const onSelectChange = val => {
        const newTable = { ...table }
        newTable.length = val
        newTable.page = 1
        onChange(newTable)
    }

    const onSort = (column, dir) => {
        const newTable = { ...table }
        newTable.column = column
        newTable.dir = dir
        newTable.page = 1
        onChange(newTable)
    }

    const onSearch = val => {
        const newTable = { ...table }
        newTable.search = val
        newTable.page = 1
        onChange(newTable)
    }

    const getCSV = type => {
        apiExport()
            .then(({ data }) => downloadType(type, data))
            .catch(error => error)
    }

    return (
        <Cart divclass="">
            <Formik>
                {() => (
                    <>
                        <div className={`p-3 md:p-4 xl:p-6 ${gridClass}`}>
                            <div className={subGridClass}>
                                <div className="flex flex-row items-center gap-2">
                                    <p className="text-black dark:text-white">{'Show'}</p>
                                    <Select
                                        id="length"
                                        name="length"
                                        value={length ?? 10}
                                        className="w-17"
                                        options={[
                                            {
                                                id: 10,
                                                label: 10,
                                            },
                                            {
                                                id: 25,
                                                label: 25,
                                            },
                                            {
                                                id: 50,
                                                label: 50,
                                            },
                                            {
                                                id: 100,
                                                label: 100,
                                            },
                                        ]}
                                        onChange={e => onSelectChange?.(e.target.value)}
                                    />
                                    <p className="text-black dark:text-white">{'Entries'}</p>
                                </div>
                                {children}
                            </div>
                            <div className={subGridClass}>
                                {search_show && (
                                    <TextInput
                                        id="search"
                                        name="search"
                                        value={search ?? ''}
                                        onChange={e => onSearch?.(e.target.value)}
                                        placeholder={'Search'}
                                        icon={<BiSearchAlt />}
                                        className="w-full sm:w-auto"
                                    />
                                )}
                                {(apiExport || add_new) && (
                                    <div className="flex flex-row items-center w-full gap-3 sm:w-auto">
                                        {apiExport && (
                                            <>
                                                <Button
                                                    as="button"
                                                    className="text-primary border-primary dark:text-secondary dark:border-secondary"
                                                    onClick={() => getCSV('csv')}
                                                >
                                                    CSV
                                                </Button>
                                                <Button
                                                    as="button"
                                                    className="text-primary border-primary dark:text-secondary dark:border-secondary"
                                                    onClick={() => getCSV('xlsx')}
                                                >
                                                    Excel
                                                </Button>
                                            </>
                                        )}
                                        {add_new && (
                                            <Link to={add_new}>
                                                <Button
                                                    as="button"
                                                    className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                                >
                                                    <AiOutlinePlus size={20} /> {'Add New'}
                                                </Button>
                                            </Link>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="max-w-full overflow-x-auto">
                            <table className="w-full table-auto">
                                <thead>
                                    <tr className={`bg-primary dark:bg-secondary bg-opacity-90 text-left`}>
                                        {select && (
                                            <th className="px-3 py-2 font-medium text-white md:px-3 2xl:px-3">
                                                <Checkbox
                                                    id={'select_all'}
                                                    name={'select_all'}
                                                    checked={datas?.length !== 0 && datas?.length === ids.length}
                                                    onChange={() =>
                                                        setIds(datas?.length !== ids.length ? datas?.map(row => row.id) : [])
                                                    }
                                                />
                                            </th>
                                        )}
                                        {columns.map((row, index) => (
                                            <th
                                                key={index}
                                                className={`font-medium text-white ${defoltclassName} ${row.className}`}
                                            >
                                                {row.orderable ? (
                                                    <div
                                                        className="flex items-center justify-between gap-1 cursor-pointer"
                                                        onClick={() => {
                                                            if (dir !== 'desc') {
                                                                onSort?.(
                                                                    row.field,
                                                                    column === row.field && dir === 'asc' ? 'desc' : 'asc'
                                                                )
                                                            } else {
                                                                onSort?.('', '')
                                                            }
                                                        }}
                                                    >
                                                        <p>{row.header}</p>
                                                        <div className="inline-flex flex-col space-y-[2px]">
                                                            {(column !== row.field || dir !== 'asc') && (
                                                                <AiFillCaretUp size={14} style={{ margin: '-1px' }} />
                                                            )}
                                                            {(column !== row.field || dir !== 'desc') && (
                                                                <AiFillCaretDown size={14} style={{ margin: '-1px' }} />
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    row.header
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {datas?.map((row, index) => (
                                        <tr key={index} className="hover:bg-whiten dark:hover:bg-boxdark2">
                                            {select && (
                                                <td className="items-center px-3 py-2 text-sm text-black border-b border-stroke dark:border-strokedark dark:text-white md:px-3 2xl:px-3">
                                                    <Checkbox
                                                        id={'select_' + row.id}
                                                        name={'select_' + row.id}
                                                        checked={ids.includes(row.id)}
                                                        onChange={() => {
                                                            var selectIds = [...ids]
                                                            if (!selectIds.includes(row.id)) {
                                                                selectIds.push(row.id)
                                                            } else {
                                                                selectIds = selectIds.filter(g => g !== row.id)
                                                            }
                                                            setIds(selectIds)
                                                        }}
                                                    />
                                                </td>
                                            )}
                                            {columns.map((column, key) => (
                                                <ColumnTd key={key} row={row} column={column} />
                                            ))}
                                        </tr>
                                    ))}
                                    {datas?.length === 0 && (
                                        <tr>
                                            <td
                                                className={`border-b border-stroke dark:border-strokedark items-center text-center text-primary dark:text-white ${defoltclassName}`}
                                                colSpan="100%"
                                            >
                                                {loading ? 'Loading...' : 'Data not found'}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {totalPage > 1 && (
                            <div className="flex flex-col gap-3 p-3 sm:p-4 xl:p-6 sm:flex-row sm:items-center sm:justify-between">
                                <p className="text-sm text-black dark:text-white">
                                    Showing {from} to {to} of {total} entries
                                </p>
                                <nav>
                                    <ul className="flex flex-wrap items-center w-full">
                                        {pages?.map((link, key) => (
                                            <li key={key}>
                                                {link !== page ? (
                                                    <div
                                                        onClick={() => {
                                                            if (loading) return 0
                                                            if (typeof link === 'string') {
                                                                if (link === 'Previous') {
                                                                    if (page !== 1) {
                                                                        onPaginationChange(page - 1)
                                                                    }
                                                                } else if (link === 'Next') {
                                                                    if (page !== totalPage) {
                                                                        onPaginationChange(page + 1)
                                                                    }
                                                                }
                                                            } else {
                                                                onPaginationChange(link)
                                                            }
                                                        }}
                                                        className={`flex items-center justify-center border py-[5px] px-4 font-medium text-sm  
                                                            ${key !== 0 && link !== page && 'border-l-transparent'}
                                                            ${
                                                                link === page
                                                                    ? 'border-primary bg-gray text-primary dark:border-secondary dark:bg-graydark'
                                                                    : 'text-black dark:text-white border-stroke hover:border-primary hover:bg-gray hover:text-primary dark:hover:text-secondary dark:border-strokedark dark:hover:border-secondary dark:hover:bg-graydark'
                                                            }
                                                            ${key === 0 && 'rounded-l-md'}
                                                            ${pages.length === key + 1 && 'rounded-r-md'}
                                                        `}
                                                    >
                                                        {link}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`flex items-center justify-center border py-[5px] px-4 font-medium text-sm 
                                                            ${key !== 0 && link !== page && 'border-l-transparent'}
                                                            ${key === 0 && 'border-r-transparent'}
                                                            ${
                                                                link === page
                                                                    ? 'border-primary bg-gray text-primary dark:border-secondary dark:bg-graydark'
                                                                    : 'text-black dark:text-white border-stroke dark:border-strokedark'
                                                            }
                                                            ${key === 0 && 'rounded-l-md'}
                                                            ${pages.length === key + 1 && 'rounded-r-md'}
                                                        `}
                                                    >
                                                        {link}
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        )}
                    </>
                )}
            </Formik>
        </Cart>
    )
}
export default Table
