export default function Logo({ type = 'logo', logo_urls = [], className, ...props }) {
    var logoSrc = '/images/logo/logo.png'
    var maxW = 'max-w-44'

    switch (type) {
        case 'dark':
            logoSrc = '/images/logo/logo-dark.png'
            maxW = 'max-w-44'
            break
        case 'icon':
            logoSrc = '/images/logo/logo-icon.png'
            maxW = 'w-8'
            break
        default:
            logoSrc = '/images/logo/logo.png'
            maxW = 'max-w-44'
    }
    return <img src={logoSrc} alt={type} className={`${maxW} ${className}`} {...props} />
}
