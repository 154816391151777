import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    apiListAffiliates,
    apiListBagTypes,
    apiListBatchRejectTypes,
    apiListBulkoBags,
    apiListCages,
    apiListCollectionPoints,
    apiListCollectors,
    apiListMaterials,
    apiListMeasurements,
    apiListProjects,
    apiListUsers,
} from 'Services/AdminService'

export const getCollectionPoints = createAsyncThunk('admin/list/getCollectionPoints', async () => {
    const { data } = await apiListCollectionPoints()
    return data
})

export const getAffiliates = createAsyncThunk('admin/list/getAffiliates', async () => {
    const { data } = await apiListAffiliates()
    return data
})

export const getCollectors = createAsyncThunk('admin/list/getCollectors', async () => {
    const { data } = await apiListCollectors()
    return data
})

export const getMeasurements = createAsyncThunk('admin/list/getMeasurements', async () => {
    const { data } = await apiListMeasurements()
    return data
})

export const getProjects = createAsyncThunk('admin/list/getProjects', async () => {
    const { data } = await apiListProjects()
    return data
})

export const getMaterials = createAsyncThunk('admin/list/getMaterials', async () => {
    const { data } = await apiListMaterials()
    return data
})

export const getBatchRejectTypes = createAsyncThunk('user/list/getBatchRejectTypes', async () => {
    const { data } = await apiListBatchRejectTypes()
    data.push({
        id: 'others',
        name: 'Others',
    })
    return data
})

export const getCages = createAsyncThunk('admin/list/getCages', async ids => {
    const { data } = await apiListCages(ids.type, ids.id)
    return data
})

export const getBulkoBags = createAsyncThunk('admin/list/getBulkoBags', async ids => {
    const { data } = await apiListBulkoBags(ids.type, ids.id)
    return data
})

export const getBagTypes = createAsyncThunk('admin/list/getBagTypes', async () => {
    const { data } = await apiListBagTypes()
    return data
})

export const getCollectionPointUsers = createAsyncThunk('admin/list/getCollectionPointUsers', async () => {
    const { data } = await apiListUsers(0)
    return data
})

export const getAffiliateUsers = createAsyncThunk('admin/list/getAffiliateUsers', async () => {
    const { data } = await apiListUsers(1)
    return data
})

export const listSlice = createSlice({
    name: 'admin/list',
    initialState: {
        collectionPoints: [],
        affiliates: [],
        collectors: [],
        measurements: [],
        projects: [],
        materials: [],
        batchRejectTypes: [],
        cages: [],
        bulko_bags: [],
        bag_types: [],
        collection_point_users: [],
        affiliate_users: [],
    },
    extraReducers: builder => {
        builder
            .addCase(getCollectionPoints.fulfilled, (state, { payload }) => {
                state.collectionPoints = payload
            })
            .addCase(getCollectionPoints.rejected, state => {
                state.collectionPoints = []
            })

            .addCase(getAffiliates.fulfilled, (state, { payload }) => {
                state.affiliates = payload
            })
            .addCase(getAffiliates.rejected, state => {
                state.affiliates = []
            })

            .addCase(getCollectors.fulfilled, (state, { payload }) => {
                state.collectors = payload
            })
            .addCase(getCollectors.rejected, state => {
                state.collectors = []
            })

            .addCase(getMeasurements.fulfilled, (state, { payload }) => {
                state.measurements = payload
            })
            .addCase(getMeasurements.rejected, state => {
                state.measurements = []
            })

            .addCase(getProjects.fulfilled, (state, { payload }) => {
                state.projects = payload
            })
            .addCase(getProjects.rejected, state => {
                state.projects = []
            })

            .addCase(getMaterials.fulfilled, (state, { payload }) => {
                state.materials = payload
            })
            .addCase(getMaterials.rejected, state => {
                state.materials = []
            })

            .addCase(getBatchRejectTypes.fulfilled, (state, { payload }) => {
                state.batchRejectTypes = payload
            })
            .addCase(getBatchRejectTypes.rejected, state => {
                state.batchRejectTypes = []
            })

            .addCase(getCages.fulfilled, (state, { payload }) => {
                state.cages = payload
            })
            .addCase(getCages.rejected, state => {
                state.cages = []
            })

            .addCase(getBulkoBags.fulfilled, (state, { payload }) => {
                state.bulko_bags = payload
            })
            .addCase(getBulkoBags.rejected, state => {
                state.bulko_bags = []
            })

            .addCase(getBagTypes.fulfilled, (state, { payload }) => {
                state.bag_types = payload
            })
            .addCase(getBagTypes.rejected, state => {
                state.bag_types = []
            })

            .addCase(getCollectionPointUsers.fulfilled, (state, { payload }) => {
                state.collection_point_users = payload
            })
            .addCase(getCollectionPointUsers.rejected, state => {
                state.collection_point_users = []
            })

            .addCase(getAffiliateUsers.fulfilled, (state, { payload }) => {
                state.affiliate_users = payload
            })
            .addCase(getAffiliateUsers.rejected, state => {
                state.affiliate_users = []
            })
    },
})

export default listSlice.reducer
