import { BrowserRouter } from 'react-router-dom'
import 'assets/css/app.css'
import 'assets/css/BarlowSemiCondensed.css'
import store, { persistor } from 'Store/index'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Router from 'Router'
import { Axios } from 'Components/Loader'

export default function App() {
    return (
        <Provider store={store}>
            <Axios />
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <ToastContainer />
                    <Router />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}
