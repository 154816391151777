import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'
import { IconContext } from 'react-icons'

import SidebarItem from './item'

const SidebarItemCollapse = ({ item }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const { pathname } = location

    const checkActive = item => {
        if (item.active) {
            var chek = item.active.filter(e => pathname.includes(e))
            if (chek.length > 0) return true
        }
        return pathname === item.url
    }

    useEffect(() => {
        if (item.active) {
            if (item.active.map(e => pathname.includes(e)).filter(e => e).length > 0) {
                setOpen(true)
            }
        } else if (pathname === item.url) {
            setOpen(true)
        }
        // eslint-disable-next-line
    }, [item])

    return (
        <>
            <Link
                className={
                    `group relative flex items-center gap-2 py-2 px-3 pl-4 font-medium text-sm text-gray duration-300 ease-linear before:absolute before:left-0 before:h-0 before:w-1 before:bg-secondary before:duration-300 before:ease-linear hover:bg-strokedark hover:text-white hover:before:h-full ` +
                    `${checkActive(item) && 'bg-strokedark text-white before:!h-full'}`
                }
                onClick={e => {
                    e.preventDefault()
                    setOpen(!open)
                }}
            >
                {item.icon && <IconContext.Provider value={{ size: '20' }}>{item.icon}</IconContext.Provider>}
                {t(item.text)}
                <IconContext.Provider
                    value={{
                        size: '23',
                        className: 'absolute right-4 top-1/2 -translate-y-1/2 fill-current',
                    }}
                >
                    {open ? <BiChevronUp /> : <BiChevronDown />}
                </IconContext.Provider>
            </Link>
            <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                <ul className="flex flex-col gap-1 pl-6">
                    {item.child?.map((route, index) =>
                        route.child && route.child.length > 0 ? (
                            <SidebarItemCollapse item={route} key={index} />
                        ) : (
                            <SidebarItem item={route} key={index} />
                        )
                    )}
                </ul>
            </div>
        </>
    )
}

export default SidebarItemCollapse
