import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dataDefault } from 'Components/User/WasteDrops/BulkoBag'
import { dataDefault as materialTypeDefault } from 'Components/User/WasteDrops/MaterialType'
import { apiWasteDropsList, apiWasteDropsView } from 'Services/UserService'

export const getData = createAsyncThunk('user/wasteDrops/getData', async datas => {
    const { data } = await apiWasteDropsList(datas)
    return data
})

export const getView = createAsyncThunk('user/wasteDrops/getView', async id => {
    if (!id) return viewData
    const { data } = await apiWasteDropsView(id)
    data.project_id = data.project_id ?? ''
    data.images = []
    data.image_remove = []
    data.material_type = data.material_type && data.material_type.length !== 0 ? data.material_type : [materialTypeDefault]
    data.bulko_bag = data.bulko_bag && data.bulko_bag.length !== 0 ? data.bulko_bag : [dataDefault]
    return data
})

const viewData = {
    collector_id: '',
    project_id: '',
    type: '0',
    address: '',
    location: '1',
    notes: '',
    images: [],
    image_remove: [],
    material_type: [materialTypeDefault],
    bulko_bag: [dataDefault],
}

export const wasteDropsSlice = createSlice({
    name: 'user/wasteDrops',
    initialState: {
        loading: true,
        view: viewData,
        data: [],
        total: 0,
        table: {
            tabs: 0,
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
        setTabs: (state, { payload }) => {
            state.table.tabs = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = viewData
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData, setTabs } = wasteDropsSlice.actions

export default wasteDropsSlice.reducer
