import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import store from 'Store'
import { onSignOutSuccess } from 'Store/auth/sessionSlice'
import { userLoggedOut } from 'Store/auth/userSlice'
import { deepParseJson } from './deepParseJson'
const { REACT_APP_NAME } = process.env

export const authorization = config => {
    const rawPersistData = localStorage.getItem(REACT_APP_NAME)
    const persistData = deepParseJson(rawPersistData)
    let session = persistData.auth.session

    if (!session || !session.token || !session.refresh_token) {
        const { auth } = store.getState()
        session = auth.session
    }
    if (session && session.token && session.refresh_token) {
        config.headers['Authorization'] = `Bearer ${config.url === '/refresh-token' ? session.refresh_token : session.token}`
    }
    return config
}

export const apiError = error => {
    let text = null
    const { response, message } = error
    if (response) {
        const { status, data } = response
        if (status === 401) {
            text = data.message
            store.dispatch(onSignOutSuccess())
            store.dispatch(userLoggedOut())
        } else if (status === 422) {
            for (const key in data.errors) {
                for (const keys in data.errors[key]) {
                    Error(data.errors[key][keys])
                }
            }
        } else if (status === 404) {
            text = '404 Not Found'
        } else if (status === 405) {
            text = '405 Method Not Allowed'
        } else if (status === 500) {
            text = '500 Internal Server Error'
        } else if (data.message) text = data.message
    } else if (message) text = message
    if (text) Error(text)
}

export const Error = error => {
    const colorTheme = localStorage.getItem('color-theme')
    var data = {
        theme: colorTheme === null ? 'light' : colorTheme,
    }
    return toast.error(error, data)
}

export const Success = message => {
    const colorTheme = localStorage.getItem('color-theme')
    var data = {
        theme: colorTheme === null ? 'light' : colorTheme,
    }
    return toast.success(message, data)
}
