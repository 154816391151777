import { FiChevronDown } from 'react-icons/fi'

export default function Select({
    id = '',
    label = '',
    placeholder = '',
    className = '',
    error = '',
    options = [],
    value = '',
    multiple = false,
    onChange,
    ...props
}) {
    var multipleClass = `w-full rounded-lg border  p-1 font-medium outline-none transition 
        ${error ? 'border-danger focus:ring-danger focus:border-danger' : 'border-stroke focus:border-primary active:border-primary dark:focus:border-secondary dark:active:border-secondary dark:border-strokedark'} 
        ${props.disabled ? 'bg-gray dark:bg-graydark' : ''} dark:bg-strokedark`

    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div className={`relative bg-white ${multiple ? multipleClass : 'dark:bg-boxdark'}`}>
                {multiple && (
                    <div className="flex flex-wrap items-center">
                        {value.map((e, index) => {
                            var labelCheck = options.find(op => op.id === e)
                            if (!labelCheck) return null
                            return (
                                <span
                                    key={index}
                                    className="flex items-center justify-center px-1 py-1 m-1 text-sm font-medium border rounded border-stroke bg-gray dark:border-strokedark dark:bg-graydark"
                                >
                                    {labelCheck.label}
                                </span>
                            )
                        })}
                        {value.filter(e => options.find(op => op.id === e)).length === 0 && (
                            <span className="px-1 py-1 m-1 text-sm">{placeholder}</span>
                        )}
                    </div>
                )}
                <select
                    {...props}
                    id={id}
                    value={multiple ? '' : value}
                    placeholder={label ? label : placeholder}
                    className={`w-full appearance-none rounded-lg border bg-transparent py-2 px-3 outline-none transition ${error ? 'border-danger focus:ring-danger focus:border-danger' : 'border-stroke focus:border-primary active:border-primary dark:focus:border-secondary dark:active:border-secondary dark:border-strokedark'} dark:bg-strokedark disabled:cursor-not-allowed disabled:bg-gray dark:disabled:bg-graydark ${
                        multiple && 'absolute top-0 left-0 z-20 h-full opacity-0'
                    }`}
                    onChange={e => {
                        if (!multiple) return onChange?.(e)
                        const select = e.target.value
                        if (!select) return
                        var values = [...value]
                        if (values.includes(select)) {
                            values = values.filter(e => e !== select)
                        } else {
                            values.push(select)
                        }
                        onChange?.(values)
                    }}
                >
                    {placeholder && <option value="">{placeholder}</option>}
                    {options.map((option, index) => (
                        <option
                            value={option.id}
                            key={index}
                            className={multiple ? (value.includes(option.id) ? 'bg-gray dark:bg-white/30' : '') : ''}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
                {multiple && (
                    <span className="absolute -translate-y-1/2 top-1/2 right-4">
                        <FiChevronDown size={18} />
                    </span>
                )}
            </div>
            {error && <p className="text-sm text-danger">{error}</p>}
        </div>
    )
}
