import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconContext } from 'react-icons'

const SidebarItem = ({ item }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const { pathname } = location

    const checkActive = item => {
        if (item.active) {
            var chek = item.active.filter(e => pathname.includes(e))
            if (chek.length > 0) return true
        }
        return pathname === item.url
    }
    return (
        <li>
            <Link
                className={
                    `group relative flex items-center gap-2 py-2 px-3 pl-4 font-medium text-sm text-gray duration-300 ease-linear before:absolute before:left-0 before:h-0 before:w-1 before:bg-secondary before:duration-300 before:ease-linear hover:bg-strokedark hover:text-white hover:before:h-full ` +
                    `${checkActive(item) && 'bg-strokedark text-white before:!h-full'}`
                }
                to={item.url}
            >
                {item.icon && <IconContext.Provider value={{ size: '20' }}>{item.icon}</IconContext.Provider>}
                {t(item.text)}
            </Link>
        </li>
    )
}

export default SidebarItem
