import { combineReducers } from '@reduxjs/toolkit'

import list from './listSlice'
import dashboard from './dashboardSlice'
import collectorCollectionPoints from './collectorCollectionPointsSlice'
import projects from './projectsSlice'
import wasteDrops from './wasteDropsSlice'
import cages from './cagesSlice'
import bulkoBags from './bulkoBagsSlice'
import pickups from './pickupsSlice'
import batches from './batchesSlice'
import collectorBagRequests from './collectorBagRequestsSlice'
import collectionPointBagRequests from './collectionPointBagRequestsSlice'
import fileUploads from './fileUploadsSlice'
import contacts from './contactsSlice'

const reducer = combineReducers({
    list,
    dashboard,
    collectorCollectionPoints,
    projects,
    wasteDrops,
    cages,
    bulkoBags,
    pickups,
    batches,
    collectorBagRequests,
    collectionPointBagRequests,
    fileUploads,
    contacts,
})

export default reducer
