import { Link } from 'react-router-dom'

import { Button } from 'Components/Form'
import { Cart, Info } from 'Components/Ui'
import { storeName } from 'Utils/helper'
import { defoltclassName } from 'Components/Ui/Table'
import { MdDownloading } from 'react-icons/md'

export default function View({ title, view, cancel_url = null }) {
    return (
        <Cart title={title}>
            <div className="flex flex-col gap-3">
                {view.affiliate ? <Info title="Recycler">{storeName(view.affiliate)}</Info> : null}
                <Info title="Name">{view.name}</Info>
                <h3 className="pt-6 font-medium text-black dark:text-white"> Bulka Bag Bin</h3>
                <table className="w-full table-auto">
                    <thead>
                        <tr className={`bg-primary dark:bg-secondary text-left border border-stroke dark:border-strokedark`}>
                            <th className={`font-medium text-white ${defoltclassName}`}>#</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>ResiLoop Bin</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Images</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Notes</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Material</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Accept</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Reject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(view?.batch_bulko_bag ?? []).map((bag, index) => (
                            <tr
                                key={index}
                                className="border hover:bg-gray dark:hover:bg-graydark border-stroke dark:border-strokedark"
                            >
                                <td className={`items-center ${defoltclassName}`}>{index + 1}</td>
                                <td className={`items-center ${defoltclassName}`}>{bag.bulko_bag.number}</td>
                                <td className={`items-center ${defoltclassName}`}>
                                    {bag.image_url ? (
                                        <a href={bag.image_url} target="_blank" rel="noreferrer" className="cursor-pointer">
                                            <MdDownloading size={20} />
                                        </a>
                                    ) : null}
                                </td>
                                <td className={`items-center ${defoltclassName}`}>{bag.notes}</td>
                                <td className={`items-center ${defoltclassName}`}>
                                    {bag.batch_bulko_bag_material.map((material, index) => (
                                        <p key={index}>{material.material.title}</p>
                                    ))}
                                </td>
                                <td className={`items-center ${defoltclassName}`}>
                                    {bag.batch_bulko_bag_material.map((material, index) => (
                                        <p key={index}>
                                            {material.accept_bags} BG - {material.accept_weight} KG
                                        </p>
                                    ))}
                                </td>
                                <td className={`items-center ${defoltclassName}`}>
                                    {bag.batch_bulko_bag_material.map((material, index) => (
                                        <p key={index}>
                                            {material.reject_bags} BG - {material.reject_weight} KG -{' '}
                                            {material.batch_reject_type ? material.batch_reject_type.name : material.reject_type}
                                        </p>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h3 className="pt-6 font-medium text-black dark:text-white">Recycled Material Created</h3>
                <table className="w-full table-auto">
                    <thead>
                        <tr className={`bg-primary dark:bg-secondary text-left border border-stroke dark:border-strokedark`}>
                            <th className={`font-medium text-white ${defoltclassName}`}>#</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Material</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(view?.batch_material ?? []).map((bag, index) => (
                            <tr
                                key={index}
                                className="border hover:bg-gray dark:hover:bg-graydark border-stroke dark:border-strokedark"
                            >
                                <td className={`items-center ${defoltclassName}`}>{index + 1}</td>
                                <td className={`items-center ${defoltclassName}`}>{bag.material.title}</td>
                                <td className={`items-center ${defoltclassName}`}>{bag.weight}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h3 className="pt-6 font-medium text-black dark:text-white">Recycling Waste</h3>
                <table className="w-full table-auto">
                    <thead>
                        <tr className={`bg-primary dark:bg-secondary text-left border border-stroke dark:border-strokedark`}>
                            <th className={`font-medium text-white ${defoltclassName}`}>#</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Material</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Weight</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Reject Type</th>
                            <th className={`font-medium text-white ${defoltclassName}`}>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(view?.batch_reject_material ?? []).map((bag, index) => (
                            <tr
                                key={index}
                                className="border hover:bg-gray dark:hover:bg-graydark border-stroke dark:border-strokedark"
                            >
                                <td className={`items-center ${defoltclassName}`}>{index + 1}</td>
                                <td className={`items-center ${defoltclassName}`}>{bag.material.title}</td>
                                <td className={`items-center ${defoltclassName}`}>{bag.weight}</td>
                                <td className={`items-center ${defoltclassName}`}>
                                    {bag.batch_reject_type ? bag.batch_reject_type.name : bag.reject_type}
                                </td>
                                <td className={`items-center ${defoltclassName}`}>{bag.notes}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {cancel_url ? (
                <div className="flex items-center gap-3 mt-6">
                    <Link to={cancel_url}>
                        <Button type="button" className="text-primary border-primary dark:text-secondary dark:border-secondary">
                            Cancel
                        </Button>
                    </Link>
                </div>
            ) : null}
        </Cart>
    )
}
