import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiListCollectionPoints } from 'Services/AuthService'

export const getCollectionPoints = createAsyncThunk('admin/list/getCollectionPoints', async () => {
    const { data } = await apiListCollectionPoints()
    return data
})

export const listSlice = createSlice({
    name: 'auth/list',
    initialState: {
        collectionPoints: [],
    },
    extraReducers: builder => {
        builder
            .addCase(getCollectionPoints.fulfilled, (state, { payload }) => {
                state.collectionPoints = payload
            })
            .addCase(getCollectionPoints.rejected, state => {
                state.collectionPoints = []
            })
    },
})

export default listSlice.reducer
