import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BiArrowBack } from 'react-icons/bi'

import SidebarItem from './item'
import SidebarItemCollapse from './itemCollapse'
import { Logo } from 'Components/Ui'

export default function Sidebar({ toUrl, appRoutes, sidebarOpen, setSidebarOpen }) {
    const { t } = useTranslation()
    const sidebar = useRef(null)
    const { user } = useSelector(state => state.auth)

    const checkRoute = items => {
        if (!user) return items
        if (['user'].includes(user.guard)) {
            if (!user.authorize.includes(items.permission)) {
                if (!items.child) return false
                items.child = items.child.filter(e => (checkRoute(e) ? true : false)).map(e => checkRoute(e))
                if (items.child.length <= 0) {
                    return false
                }
            }
        }
        return items
    }

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (sidebarOpen && sidebar.current && !sidebar.current.contains(target)) {
                setSidebarOpen(false)
            }
        }
        document.addEventListener('click', clickHandler)
        return () => document.removeEventListener('click', clickHandler)
    })
    return (
        <aside
            ref={sidebar}
            className={`absolute left-0 top-0 z-20 flex h-screen w-65 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
        >
            <div className="flex items-center justify-between gap-2 px-6 py-4 lg:py-5">
                <Link to={toUrl}>
                    <Logo />
                </Link>
                <button
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    className="block lg:hidden"
                >
                    <BiArrowBack size={25} />
                </button>
            </div>
            <div className="flex flex-col overflow-y-auto duration-300 ease-linear no-scrollbar">
                <nav className="px-3 py-3">
                    {appRoutes
                        .filter(e => (checkRoute(e) ? true : false))
                        .map(e => checkRoute(e))
                        .map((route, index) => (
                            <div key={index}>
                                <h3 className="mb-3 ml-3 text-sm font-semibold text-gray/50">{t(route.text)}</h3>
                                <ul className="flex flex-col gap-1 mb-3">
                                    {route.child.map((item, key) =>
                                        item.child && item.child.length > 0 ? (
                                            <SidebarItemCollapse item={item} key={key} />
                                        ) : (
                                            <SidebarItem item={item} key={key} />
                                        )
                                    )}
                                </ul>
                            </div>
                        ))}
                </nav>
            </div>
        </aside>
    )
}
