import { combineReducers } from '@reduxjs/toolkit'

import batchDetailed from './batchDetailedSlice'
import collectorDropOff from './collectorDropOffSlice'
import collectorBagDistribution from './collectorBagDistributionSlice'
import collectionPointBagDistribution from './collectionPointBagDistributionSlice'
import project from './projectSlice'
import monthly from './monthlySlice'

const reducer = combineReducers({
    batchDetailed,
    collectorDropOff,
    collectorBagDistribution,
    collectionPointBagDistribution,
    project,
    monthly,
})

export default reducer
