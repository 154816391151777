import ApiService from './ApiService'

export const apiListCollectionPoints = () => ApiService.get('/list/collection-points')

export const apiLogIn = data => ApiService.post('/login', data)
export const apiRefreshToken = () => ApiService.get('/refresh-token')
export const apiForgotPassword = data => ApiService.post('/forgot-password', data)
export const apiResetPassword = data => ApiService.post('/reset-password', data)
export const apiRegister = (type, data) => ApiService.post(`/register/${type}`, data)

export const apiLogOut = guard => ApiService.post(`/${guard}/logout`)
export const apiUser = guard => ApiService.get(`/${guard}`)
export const apiResend = guard => ApiService.get(`/${guard}/resend`)
export const apiVerifyEmail = (guard, id, hash, expires, signature) =>
    ApiService.post(`/${guard}/verify-email/${id}/${hash}?expires=${expires}&signature=${signature}`)
export const apiNotification = guard => ApiService.get(`/${guard}/notification`)
export const apiReadNotification = guard => ApiService.post(`/${guard}/read-notification`)
export const apiChangePassword = (guard, data) => ApiService.post(`/${guard}/change-password`, data)
export const apiProfile = (guard, data) => ApiService.post(`/${guard}/profile`, data)
export const apiDashboard = (guard, data) => ApiService.post(`/${guard}/dashboard`, data)
export const apiCkeditor = data => ApiService.post(`/ckeditor`, data)
