import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment/moment'
import { apiDashboard } from 'Services/AuthService'

export const getDashboard = createAsyncThunk('admin/dashboard/getDashboard', async datas => {
    const { data } = await apiDashboard(datas.guard, datas)
    return data
})

const viewData = {
    total: [],
}

export const dashboardSlice = createSlice({
    name: 'admin/dashboard',
    initialState: {
        loading: true,
        start: moment().startOf('month').locale('en').format('YYYY-MM-DD'),
        end: moment().endOf('month').locale('en').format('YYYY-MM-DD'),
        view: viewData,
    },
    reducers: {
        setDate: (state, { payload: { start, end } }) => {
            state.start = start
            state.end = end
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getDashboard.pending, state => {
                state.loading = true
                state.view = viewData
            })
            .addCase(getDashboard.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setDate } = dashboardSlice.actions

export default dashboardSlice.reducer
