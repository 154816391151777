import { combineReducers } from '@reduxjs/toolkit'

import list from './listSlice'
import dashboard from './dashboardSlice'
import admins from './adminsSlice'
import collectionPoints from './collectionPointsSlice'
import affiliates from './affiliatesSlice'
import users from './usersSlice'
import collectors from './collectorsSlice'
import projects from './projectsSlice'
import measurements from './measurementsSlice'
import materials from './materialsSlice'
import batchRejectTypes from './batchRejectTypesSlice'
import wasteDrops from './wasteDropsSlice'
import cages from './cagesSlice'
import bulkoBags from './bulkoBagsSlice'
import pickups from './pickupsSlice'
import batches from './batchesSlice'
import bagTypes from './bagTypesSlice'
import collectorBagRequests from './collectorBagRequestsSlice'
import collectionPointBagRequests from './collectionPointBagRequestsSlice'
import fileUploads from './fileUploadsSlice'
import contacts from './contactsSlice'
import memberProducts from './memberProductsSlice'
import report from './report'

const reducer = combineReducers({
    list,
    dashboard,
    admins,
    collectionPoints,
    affiliates,
    users,
    collectors,
    projects,
    measurements,
    materials,
    batchRejectTypes,
    wasteDrops,
    cages,
    bulkoBags,
    pickups,
    batches,
    bagTypes,
    collectorBagRequests,
    collectionPointBagRequests,
    fileUploads,
    contacts,
    memberProducts,
    report,
})

export default reducer
