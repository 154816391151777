import { Cart, Logo } from 'Components/Ui'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, Outlet, useLocation } from 'react-router-dom'
import useAuth from 'Utils/Hooks/useAuth'

export default function Auth({ guard }) {
    const location = useLocation()
    const { loading, authenticated, loginCheck } = useAuth()
    const { user } = useSelector(state => state.auth)
    useEffect(() => {
        loginCheck(authenticated, guard, location.pathname)
        // eslint-disable-next-line
    }, [location.pathname, user])

    useEffect(() => {
        const className = 'dark'
        const colorTheme = localStorage.getItem('color-theme')
        if (colorTheme === 'dark') {
            document.querySelector('body')?.classList.add(className)
        } else {
            document.querySelector('body')?.classList.remove(className)
        }
        // eslint-disable-next-line
    }, [])

    if (loading) return null
    return (
        <div className="flex flex-col items-center min-h-screen p-10 bg-gray dark:bg-graydark dark:text-gray/70">
            <Link to="/" className="block mb-3 xl:hidden" href="/">
                <Logo className="dark:hidden" type="dark" />
                <Logo className="hidden dark:block" type="logo" />
            </Link>
            <Cart divclass="w-full">
                <div className="flex flex-wrap items-center">
                    <div className="hidden w-full bg-center bg-cover xl:block xl:w-1/2">
                        <div className="px-40 text-center">
                            <Link to="/" className="inline-block" href="/">
                                <Logo className="dark:hidden" type="dark" />
                                <Logo className="hidden dark:block" type="logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="w-full max-w-xl border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
                        <div className="p-6 min-w-100 min-h-100">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </Cart>
        </div>
    )
}
