import prettyBytes from 'pretty-bytes'
import { FiTrash2 } from 'react-icons/fi'

import { Tooltip } from 'Components/Ui'
import { defoltclassName } from 'Components/Ui/Table'

export default function FileUpload({
    id = '',
    label = '',
    placeholder = '',
    className = '',
    error = '',
    message = '',
    documentLists = false,
    removeDocument,
    ...props
}) {
    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div className="relative">
                <input
                    {...props}
                    type="file"
                    id={id}
                    placeholder={label ? label : placeholder}
                    className={`w-full font-medium transition bg-transparent border rounded-lg outline-none cursor-pointer file:mr-3 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:bg-gray file:py-2 file:px-3 ${error ? 'border-danger focus:ring-danger file:border-danger file:hover:bg-danger dark:file:hover:bg-danger focus:border-danger active:border-danger dark:focus:border-danger' : 'border-stroke file:border-stroke file:hover:bg-primary dark:file:hover:bg-secondary focus:border-primary active:border-primary dark:active:border-secondary dark:border-graydark dark:file:border-strokedark dark:focus:border-secondary'} file:hover:bg-opacity-50 dark:file:hover:bg-opacity-50 disabled:cursor-not-allowed disabled:bg-gray dark:bg-strokedark dark:file:bg-graydark dark:file:text-white`}
                />
            </div>
            {message && <p>{message}</p>}
            {error && <p className="text-sm text-danger">{error}</p>}
            {documentLists && (
                <table className="w-full table-auto">
                    <tbody>
                        {documentLists.map((img, index) => (
                            <tr key={index} className="hover:bg-gray dark:hover:bg-graydark">
                                <td className={`border-b border-stroke dark:border-strokedark items-center ${defoltclassName}`}>
                                    <a
                                        href={img.file_url + '?download=' + img.name}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="cursor-pointer"
                                    >
                                        <p className="text-sm text-black dark:text-white hover:text-primary dark:hover:text-secondary">
                                            {img.name}
                                        </p>
                                    </a>
                                </td>
                                <td
                                    className={`border-b border-stroke dark:border-strokedark items-center min-w-[150px] ${defoltclassName}`}
                                >
                                    {prettyBytes(Number(img.size))}
                                </td>
                                <td className={`border-b border-stroke dark:border-strokedark items-center ${defoltclassName}`}>
                                    <div className="flex items-center gap-3">
                                        <Tooltip title="Delete">
                                            <FiTrash2
                                                size={20}
                                                className="cursor-pointer text-danger"
                                                onClick={() => removeDocument(img)}
                                            />
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    )
}
