import * as XLSX from 'xlsx'
import { keys, values } from 'lodash'

export const downloadFile = (name, type, data) => {
    const blob = new Blob([data], { type: type })

    const a = document.createElement('a')
    a.download = name
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export const downloadType = (type, data) => {
    const key = keys(data.header)

    let datasCsv = data.data.reduce((acc, d) => {
        var data = []
        key.map(e => data.push(d[e]))
        acc.push(data)
        return acc
    }, [])

    const worksheet = XLSX.utils.aoa_to_sheet([...[values(data.header)], ...datasCsv])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, data.name)

    const excelBuffer = XLSX.write(workbook, { bookType: type, type: 'array' })

    downloadFile(
        data.name + '.' + type,
        type === 'xlsx'
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            : 'text/csv;charset=utf-8',
        excelBuffer
    )
}
