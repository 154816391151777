import { combineReducers } from 'redux'
import auth from './auth'
import list from './auth/listSlice'
import notification from './auth/notificationSlice'
import locale from './locale/localeSlice'
import admin from './admin'
import user from './user'

const rootReducer = asyncReducers => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        list,
        notification,
        locale,
        admin,
        user,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
