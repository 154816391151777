import { Link } from 'react-router-dom'
import { AiOutlineMenuUnfold } from 'react-icons/ai'

import { Logo } from 'Components/Ui'
import DropdownUser from './DropdownUser'
import { DarkModeSwitcher, Notifications } from '../Header'

export default function Header({ sidebarOpen, setSidebarOpen }) {
    return (
        <header className="sticky top-0 z-10 flex w-full bg-white dark:bg-boxdark">
            <div className="flex items-center justify-between flex-grow px-2 py-2 shadow-md dark:shadow-strokedark">
                <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
                    <button
                        aria-controls="sidebar"
                        onClick={e => {
                            e.stopPropagation()
                            setSidebarOpen(!sidebarOpen)
                        }}
                        className="block bg-white border rounded-sm border-stroke dark:border-strokedark dark:bg-boxdark lg:hidden"
                    >
                        <AiOutlineMenuUnfold size={30} className="relative block cursor-pointer" />
                    </button>

                    <Link to="/admin" className="w-8 mr-2">
                        <Logo className="w-10 dark:hidden" type="dark" />
                        <Logo className="hidden w-10 dark:block" type="logo" />
                    </Link>
                </div>
                <div className="hidden sm:block"></div>
                <div className="flex items-center gap-3 sm:gap-4">
                    <ul className="flex items-center gap-2 sm:gap-4">
                        <li>
                            <DarkModeSwitcher />
                        </li>
                        <li>
                            <Notifications />
                        </li>
                    </ul>
                    <DropdownUser />
                </div>
            </div>
        </header>
    )
}
