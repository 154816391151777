import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiMemberProductsList, apiMemberProductsView } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/memberProducts/getData', async datas => {
    const { data } = await apiMemberProductsList(datas)
    return data
})

export const getView = createAsyncThunk('admin/memberProducts/getView', async id => {
    if (!id) return viewData
    const { data } = await apiMemberProductsView(id)
    data.logo = ''
    return data
})

const viewData = {
    name: '',
    logo: '',
    notes: '',
    type: '0',
}

export const memberProductsSlice = createSlice({
    name: 'admin/memberProducts',
    initialState: {
        loading: true,
        view: viewData,
        data: [],
        total: 0,
        table: {
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = viewData
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData } = memberProductsSlice.actions

export default memberProductsSlice.reducer
