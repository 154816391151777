import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiNotification, apiReadNotification } from 'Services/AuthService'

export const getNotificationData = createAsyncThunk('notification/getNotificationData', async guard => {
    const { data } = await apiNotification(guard)
    return data
})

export const readNotificationData = createAsyncThunk('notification/readNotificationData', async guard => {
    const { data } = await apiReadNotification(guard)
    return data
})

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: [],
    reducers: {
        setNotification: (_, action) => action.payload,
    },
    extraReducers: builder => {
        builder.addCase(getNotificationData.fulfilled, (_, action) => action.payload)
        builder.addCase(readNotificationData.fulfilled, _ => [])
    },
})

export const { setNotification } = notificationSlice.actions

export default notificationSlice.reducer
