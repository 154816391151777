import ApiService from './ApiService'

export const apiListCollectors = () => ApiService.get('/user/list/collectors')
export const apiListProjects = () => ApiService.get('/user/list/projects')
export const apiListMaterials = () => ApiService.get('/user/list/materials')
export const apiListBatchRejectTypes = () => ApiService.get('/user/list/batch-reject-types')
export const apiListCages = () => ApiService.get('/user/list/cages')
export const apiListBulkoBags = () => ApiService.get('/user/list/bulko-bags')
export const apiListBagTypes = () => ApiService.get('/user/list/bag-types')

export const apiCollectorCollectionPointsList = data => ApiService.get('/user/collector-collection-points', data)
export const apiCollectorCollectionPointsView = id => ApiService.get(`/user/collector-collection-points/${id}`)
export const apiCollectorCollectionPointsForm = (id, data) =>
    ApiService.post(`/user/collector-collection-points${id ? `/${id}` : ''}`, data)
export const apiCollectorCollectionPointsStatus = (id, data) =>
    ApiService.post(`/user/collector-collection-points/${id}/status`, data)

export const apiProjectsList = data => ApiService.get('/user/projects', data)
export const apiProjectsView = id => ApiService.get(`/user/projects/${id}`)
export const apiProjectsForm = (id, data) => ApiService.post(`/user/projects${id ? `/${id}` : ''}`, data)
export const apiProjectsStatus = id => ApiService.post(`/user/projects/${id}/status`)

export const apiWasteDropsList = data => ApiService.get('/user/waste-drops', data)
export const apiWasteDropsView = id => ApiService.get(`/user/waste-drops/${id}`)
export const apiWasteDropsForm = (id, data) => ApiService.post(`/user/waste-drops${id ? `/${id}` : ''}`, data)
export const apiWasteDropsStatus = (id, data) => ApiService.post(`/user/waste-drops/${id}/status`, data)
export const apiWasteDropsBulkoBag = (id, data) => ApiService.post(`/user/waste-drops/${id}/bulko-bag`, data)

export const apiCagesList = data => ApiService.get('/user/cages', data)
export const apiCagesView = id => ApiService.get(`/user/cages/${id}`)
export const apiCagesForm = (id, data) => ApiService.post(`/user/cages${id ? `/${id}` : ''}`, data)
export const apiCagesStatus = id => ApiService.post(`/user/cages/${id}/status`)

export const apiBulkoBagsDownload = data => ApiService.post(`/user/bulko-bags-download`, data, { responseType: 'blob' })
export const apiBulkoBagsList = data => ApiService.get('/user/bulko-bags', data)
export const apiBulkoBagsView = id => ApiService.get(`/user/bulko-bags/${id}`)
export const apiBulkoBagsForm = (id, data) => ApiService.post(`/user/bulko-bags${id ? `/${id}` : ''}`, data)
export const apiBulkoBagsStatus = id => ApiService.post(`/user/bulko-bags/${id}/status`)

export const apiPickupsList = data => ApiService.get('/user/pickups', data)
export const apiPickupsView = id => ApiService.get(`/user/pickups/${id}`)
export const apiPickupsForm = (id, data) => ApiService.post(`/user/pickups${id ? `/${id}` : ''}`, data)
export const apiPickupsStatus = (id, data) => ApiService.post(`/user/pickups/${id}/status`, data)

export const apiBatchesList = data => ApiService.get('/user/batches', data)
export const apiBatchesView = id => ApiService.get(`/user/batches/${id}`)
export const apiBatchesForm = (id, data) => ApiService.post(`/user/batches${id ? `/${id}` : ''}`, data)
export const apiBatchesStatus = (id, data) => ApiService.post(`/user/batches/${id}/status`, data)

export const apiCollectorBagRequestsList = data => ApiService.get('/user/collector/bag-requests', data)
export const apiCollectorBagRequestsView = id => ApiService.get(`/user/collector/bag-requests/${id}`)
export const apiCollectorBagRequestsForm = (id, data) =>
    ApiService.post(`/user/collector/bag-requests${id ? `/${id}` : ''}`, data)
export const apiCollectorBagRequestsStatus = (id, data) => ApiService.post(`/user/collector/bag-requests/${id}/status`, data)

export const apiCollectionPointBagRequestsList = data => ApiService.get('/user/collection-point/bag-requests', data)
export const apiCollectionPointBagRequestsView = id => ApiService.get(`/user/collection-point/bag-requests/${id}`)
export const apiCollectionPointBagRequestsForm = (id, data) =>
    ApiService.post(`/user/collection-point/bag-requests${id ? `/${id}` : ''}`, data)

export const apiFileUploadsList = data => ApiService.get('/user/file-uploads', data)

export const apiContactsList = data => ApiService.get('/user/contacts', data)
export const apiContactsView = id => ApiService.get(`/user/contacts/${id}`)
export const apiContactsForm = data => ApiService.post('/user/contacts', data)
