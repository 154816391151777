import { buildFormData } from 'Utils/deepParseJson'
import BaseService from './BaseService'

const ApiService = {
    get(url, params) {
        return BaseService({
            method: 'GET',
            url: url,
            params,
        })
    },
    post(url, data, datas = {}) {
        var form = new FormData()
        buildFormData(form, data)
        return BaseService({
            method: 'POST',
            url: url,
            data: form,
            ...datas,
        })
    },
    patch(url, data) {
        var form = new FormData()
        buildFormData(form, data)
        return BaseService({
            method: 'PATCH',
            url: url,
            data: form,
        })
    },
    update(url, slug, data) {
        var form = new FormData()
        buildFormData(form, data)
        return BaseService({
            method: 'PUT',
            url: url + '/' + slug,
            data: form,
        })
    },
    put(url, data) {
        var form = new FormData()
        buildFormData(form, data)
        return BaseService({
            method: 'PUT',
            url: url,
            data: form,
        })
    },
}

export default ApiService
