import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'

import {
    apiChangePassword,
    apiForgotPassword,
    apiLogIn,
    apiLogOut,
    apiProfile,
    apiRefreshToken,
    apiRegister,
    apiResend,
    apiResetPassword,
    apiUser,
    apiVerifyEmail,
} from 'Services/AuthService'
import { setUser, userLoggedOut } from 'Store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess, setToken } from 'Store/auth/sessionSlice'
import { Success } from 'Utils/alerts'

function useAuth() {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { session, user } = useSelector(state => state.auth)

    const signIn = (values, { setSubmitting }) => {
        setSubmitting(true)
        apiLogIn(values)
            .then(({ data }) => {
                dispatch(onSignInSuccess(data))
                dispatch(setUser(data.user))
                Success(data.message)
                navigate('/' + data.user.guard)
            })
            .catch(error => error)
            .finally(() => setSubmitting(false))
    }

    const forgotPassword = (values, { setSubmitting }) => {
        setSubmitting(true)
        apiForgotPassword(values)
            .then(({ data }) => {
                Success(data.message)
                navigate('/login')
            })
            .catch(error => error)
            .finally(() => setSubmitting(false))
    }

    const resetPassword = (values, { setSubmitting }) => {
        setSubmitting(true)
        apiResetPassword(values)
            .then(({ data }) => {
                Success(data.message)
                navigate('/login')
            })
            .catch(error => error)
            .finally(() => setSubmitting(false))
    }

    const register = (values, { setSubmitting }) => {
        setSubmitting(true)
        apiRegister(values.register_type, values)
            .then(({ data }) => {
                Success(data.message)
                navigate('/login')
            })
            .catch(error => error)
            .finally(() => setSubmitting(false))
    }

    const signOut = () => {
        apiLogOut(user.guard)
            .then(({ data }) => Success(data.message))
            .catch(error => error)
            .finally(() => handleSignOut())
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(userLoggedOut())
        navigate('/login')
    }

    const getUser = guard => {
        apiUser(guard)
            .then(({ data }) => dispatch(setUser(data)))
            .catch(() => handleSignOut())
            .finally(() => setTimeout(() => setLoading(false), 100))
    }

    const resendEmail = setSubmitting => {
        setSubmitting(true)
        apiResend(user.guard)
            .then(({ data }) => Success(data.message))
            .catch(error => error)
            .finally(() => setSubmitting(false))
    }

    const verifyEmail = (id, hash, expires, signature) => {
        if (!user || !user.guard) {
            return
        }
        apiVerifyEmail(user.guard, id, hash, expires, signature)
            .then(({ data }) => {
                Success(data.message)
                getUser(user.guard)
            })
            .catch(error => error)
    }

    const checkToken = (guard, location, type = null) => {
        var now = moment().add(5, 'minute').format('YYYY-MM-DD HH:mm:ss')
        var token_expires_at = moment(session.token_expires_at).format('YYYY-MM-DD HH:mm:ss')
        var refresh_token_expires_at = moment(session.refresh_token_expires_at).format('YYYY-MM-DD HH:mm:ss')
        if (session.refresh_token_expires_at && now > refresh_token_expires_at) {
            signOut()
        } else if (now > token_expires_at) {
            apiRefreshToken()
                .then(({ data }) => dispatch(setToken(data)))
                .catch(() => handleSignOut())
                .finally(() => setTimeout(() => setLoading(false), 100))
        } else if (user && user.now) {
            var verify_emai = `/${user.guard}/verify-email`
            if (now > user.now) {
                getUser(guard)
            } else if (!user.email_verified_at) {
                if (!location.includes(verify_emai)) {
                    navigate(verify_emai)
                } else {
                    setLoading(false)
                }
            } else if (location.includes(verify_emai) || (type && user.type.toString() && type !== user.type.toString())) {
                navigate(`/${user.guard}`)
            } else {
                setLoading(false)
            }
        }
    }

    const loginCheck = (authenticated, guard, location, type = null) => {
        if (guard === 'guest') {
            if (authenticated) {
                navigate(`/${user.guard}`)
            } else {
                setLoading(false)
            }
        } else {
            if (authenticated) {
                if (guard !== user.guard) {
                    navigate(`/${user.guard}`)
                } else {
                    checkToken(user.guard, location, type)
                }
            } else {
                navigate('/login')
            }
        }
    }

    const changePassword = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        apiChangePassword(user.guard, values)
            .then(({ data }) => {
                Success(data.message)
                resetForm()
            })
            .catch(error => error)
            .finally(() => setSubmitting(false))
    }

    const profile = (values, { setSubmitting }) => {
        setSubmitting(true)
        apiProfile(user.guard, values)
            .then(({ data }) => {
                getUser(user.guard)
                Success(data.message)
            })
            .catch(error => error)
            .finally(() => setSubmitting(false))
    }

    return {
        loading,
        authenticated: session.token && session.signedIn && user.guard,
        signIn,
        forgotPassword,
        resetPassword,
        register,
        signOut,
        handleSignOut,
        resendEmail,
        verifyEmail,
        loginCheck,
        changePassword,
        profile,
    }
}

export default useAuth
