import ApiService from './ApiService'

export const apiListCollectionPoints = () => ApiService.get('/admin/list/collection-points')
export const apiListAffiliates = () => ApiService.get('/admin/list/affiliates')
export const apiListCollectors = () => ApiService.get('/admin/list/collectors')
export const apiListMeasurements = () => ApiService.get('/admin/list/measurements')
export const apiListProjects = () => ApiService.get('/admin/list/projects')
export const apiListMaterials = () => ApiService.get('/admin/list/materials')
export const apiListBatchRejectTypes = () => ApiService.get('/admin/list/batch-reject-types')
export const apiListCages = (type, id) => ApiService.get(`/admin/list/cages/${type}/${id}`)
export const apiListBulkoBags = (type, id) => ApiService.get(`/admin/list/bulko-bags/${type}/${id}`)
export const apiListBagTypes = () => ApiService.get('/admin/list/bag-types')
export const apiListUsers = type => ApiService.get(`/admin/list/users/${type}`)

export const apiAdminsList = data => ApiService.get('/admin/admins', data)
export const apiAdminsView = id => ApiService.get(`/admin/admins/${id}`)
export const apiAdminsForm = (id, data) => ApiService.post(`/admin/admins${id ? `/${id}` : ''}`, data)
export const apiAdminsStatus = id => ApiService.post(`/admin/admins/${id}/status`)
export const apiAdminsVerified = id => ApiService.post(`/admin/admins/${id}/verified`)
export const apiSendMail = data => ApiService.post('/admin/send-mail', data)

export const apiCollectionPointsList = data => ApiService.get('/admin/collection-points', data)
export const apiCollectionPointsView = id => ApiService.get(`/admin/collection-points/${id}`)
export const apiCollectionPointsForm = (id, data) => ApiService.post(`/admin/collection-points${id ? `/${id}` : ''}`, data)
export const apiCollectionPointsStatus = id => ApiService.post(`/admin/collection-points/${id}/status`)
export const apiCollectionPointsExport = () => ApiService.get(`/admin/collection-points-export`)

export const apiAffiliatesList = data => ApiService.get('/admin/affiliates', data)
export const apiAffiliatesView = id => ApiService.get(`/admin/affiliates/${id}`)
export const apiAffiliatesForm = (id, data) => ApiService.post(`/admin/affiliates${id ? `/${id}` : ''}`, data)
export const apiAffiliatesStatus = id => ApiService.post(`/admin/affiliates/${id}/status`)
export const apiAffiliatesExport = () => ApiService.get(`/admin/affiliates-export`)

export const apiUsersList = data => ApiService.get('/admin/users', data)
export const apiUsersView = id => ApiService.get(`/admin/users/${id}`)
export const apiUsersForm = (id, data) => ApiService.post(`/admin/users${id ? `/${id}` : ''}`, data)
export const apiUsersStatus = id => ApiService.post(`/admin/users/${id}/status`)
export const apiUsersVerified = id => ApiService.post(`/admin/users/${id}/verified`)
export const apiUsersExport = () => ApiService.get(`/admin/users-export`)

export const apiCollectorsList = data => ApiService.get('/admin/collectors', data)
export const apiCollectorsView = id => ApiService.get(`/admin/collectors/${id}`)
export const apiCollectorsForm = (id, data) => ApiService.post(`/admin/collectors${id ? `/${id}` : ''}`, data)
export const apiCollectorsStatus = id => ApiService.post(`/admin/collectors/${id}/status`)
export const apiCollectorsVerified = id => ApiService.post(`/admin/collectors/${id}/verified`)
export const apiCollectorsExport = () => ApiService.get(`/admin/collectors-export`)

export const apiProjectsList = data => ApiService.get('/admin/projects', data)
export const apiProjectsView = id => ApiService.get(`/admin/projects/${id}`)
export const apiProjectsForm = (id, data) => ApiService.post(`/admin/projects${id ? `/${id}` : ''}`, data)
export const apiProjectsStatus = id => ApiService.post(`/admin/projects/${id}/status`)

export const apiMeasurementsList = data => ApiService.get('/admin/measurements', data)
export const apiMeasurementsView = id => ApiService.get(`/admin/measurements/${id}`)
export const apiMeasurementsForm = (id, data) => ApiService.post(`/admin/measurements${id ? `/${id}` : ''}`, data)
export const apiMeasurementsStatus = id => ApiService.post(`/admin/measurements/${id}/status`)

export const apiMaterialsList = data => ApiService.get('/admin/materials', data)
export const apiMaterialsView = id => ApiService.get(`/admin/materials/${id}`)
export const apiMaterialsForm = (id, data) => ApiService.post(`/admin/materials${id ? `/${id}` : ''}`, data)
export const apiMaterialsStatus = id => ApiService.post(`/admin/materials/${id}/status`)

export const apiBatchRejectTypesList = data => ApiService.get('/admin/batch-reject-types', data)
export const apiBatchRejectTypesView = id => ApiService.get(`/admin/batch-reject-types/${id}`)
export const apiBatchRejectTypesForm = (id, data) => ApiService.post(`/admin/batch-reject-types${id ? `/${id}` : ''}`, data)
export const apiBatchRejectTypesStatus = id => ApiService.post(`/admin/batch-reject-types/${id}/status`)

export const apiWasteDropsList = data => ApiService.get('/admin/waste-drops', data)
export const apiWasteDropsView = id => ApiService.get(`/admin/waste-drops/${id}`)
export const apiWasteDropsForm = (id, data) => ApiService.post(`/admin/waste-drops${id ? `/${id}` : ''}`, data)
export const apiWasteDropsStatus = (id, data) => ApiService.post(`/admin/waste-drops/${id}/status`, data)
export const apiWasteDropsBulkoBag = (id, data) => ApiService.post(`/admin/waste-drops/${id}/bulko-bag`, data)

export const apiCagesList = data => ApiService.get('/admin/cages', data)
export const apiCagesView = id => ApiService.get(`/admin/cages/${id}`)
export const apiCagesForm = (id, data) => ApiService.post(`/admin/cages${id ? `/${id}` : ''}`, data)
export const apiCagesStatus = id => ApiService.post(`/admin/cages/${id}/status`)

export const apiBulkoBagsDownload = data => ApiService.post(`/admin/bulko-bags-download`, data, { responseType: 'blob' })
export const apiBulkoBagsList = data => ApiService.get('/admin/bulko-bags', data)
export const apiBulkoBagsView = id => ApiService.get(`/admin/bulko-bags/${id}`)
export const apiBulkoBagsForm = (id, data) => ApiService.post(`/admin/bulko-bags${id ? `/${id}` : ''}`, data)
export const apiBulkoBagsStatus = id => ApiService.post(`/admin/bulko-bags/${id}/status`)

export const apiPickupsList = data => ApiService.get('/admin/pickups', data)
export const apiPickupsView = id => ApiService.get(`/admin/pickups/${id}`)
export const apiPickupsForm = (id, data) => ApiService.post(`/admin/pickups${id ? `/${id}` : ''}`, data)
export const apiPickupsStatus = (id, data) => ApiService.post(`/admin/pickups/${id}/status`, data)

export const apiBatchesList = data => ApiService.get('/admin/batches', data)
export const apiBatchesView = id => ApiService.get(`/admin/batches/${id}`)
export const apiBatchesForm = (id, data) => ApiService.post(`/admin/batches${id ? `/${id}` : ''}`, data)
export const apiBatchesStatus = (id, data) => ApiService.post(`/admin/batches/${id}/status`, data)

export const apiBagTypesList = data => ApiService.get('/admin/bag-types', data)
export const apiBagTypesView = id => ApiService.get(`/admin/bag-types/${id}`)
export const apiBagTypesForm = (id, data) => ApiService.post(`/admin/bag-types${id ? `/${id}` : ''}`, data)
export const apiBagTypesStatus = (id, data) => ApiService.post(`/admin/bag-types/${id}/status`, data)

export const apiCollectorBagRequestsList = data => ApiService.get('/admin/collector/bag-requests', data)
export const apiCollectorBagRequestsView = id => ApiService.get(`/admin/collector/bag-requests/${id}`)
export const apiCollectorBagRequestsForm = (id, data) =>
    ApiService.post(`/admin/collector/bag-requests${id ? `/${id}` : ''}`, data)
export const apiCollectorBagRequestsStatus = (id, data) => ApiService.post(`/admin/collector/bag-requests/${id}/status`, data)

export const apiCollectionPointBagRequestsList = data => ApiService.get('/admin/collection-point/bag-requests', data)
export const apiCollectionPointBagRequestsView = id => ApiService.get(`/admin/collection-point/bag-requests/${id}`)
export const apiCollectionPointBagRequestsForm = (id, data) =>
    ApiService.post(`/admin/collection-point/bag-requests${id ? `/${id}` : ''}`, data)
export const apiCollectionPointBagRequestsStatus = (id, data) =>
    ApiService.post(`/admin/collection-point/bag-requests/${id}/status`, data)

export const apiFileUploadsList = data => ApiService.get('/admin/file-uploads', data)
export const apiFileUploadsView = id => ApiService.get(`/admin/file-uploads/${id}`)
export const apiFileUploadsForm = (id, data) => ApiService.post(`/admin/file-uploads${id ? `/${id}` : ''}`, data)
export const apiFileUploadsStatus = id => ApiService.post(`/admin/file-uploads/${id}/status`)

export const apiContactsList = data => ApiService.get('/admin/contacts', data)
export const apiContactsView = id => ApiService.get(`/admin/contacts/${id}`)
export const apiContactsStatus = (id, data) => ApiService.post(`/admin/contacts/${id}/status`, data)

export const apiMemberProductsList = data => ApiService.get('/admin/member-products', data)
export const apiMemberProductsView = id => ApiService.get(`/admin/member-products/${id}`)
export const apiMemberProductsForm = (id, data) => ApiService.post(`/admin/member-products${id ? `/${id}` : ''}`, data)
export const apiMemberProductsStatus = id => ApiService.post(`/admin/member-products/${id}/status`)

export const apiBatchDetailedList = data => ApiService.post('/admin/report/batch-detailed', data)
export const apiBatchDetailedView = id => ApiService.get(`/admin/report/batch-detailed/${id}`)
export const apiBatchDetailedExport = data => ApiService.get(`/admin/report/batch-detailed-export`, data)

export const apiCollectorDropOffList = data => ApiService.post('/admin/report/collector-drop-off', data)
export const apiCollectorDropOffView = id => ApiService.get(`/admin/report/collector-drop-off/${id}`)
export const apiCollectorDropOffExport = data => ApiService.get(`/admin/report/collector-drop-off-export`, data)

export const apiCollectorBagDistributionList = data => ApiService.post('/admin/report/collector-bag-distribution', data)
export const apiCollectorBagDistributionExport = data => ApiService.get('/admin/report/collector-bag-distribution-export', data)
export const apiCollectionPointBagDistributionList = data =>
    ApiService.post('/admin/report/collection-point-bag-distribution', data)
export const apiCollectionPointBagDistributionExport = data =>
    ApiService.get('/admin/report/collection-point-bag-distribution-export', data)

export const apiProjectList = data => ApiService.post('/admin/report/project', data)
export const apiProjectView = id => ApiService.get(`/admin/report/project/${id}`)
export const apiProjectExport = data => ApiService.get(`/admin/report/project-export`, data)

export const apiMonthlyList = data => ApiService.post('/admin/report/monthly', data)
