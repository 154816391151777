import { Auth } from 'Components/Layout'
import { Lazy } from 'Components/Loader'

const auth = [
    {
        element: <Auth guard="guest" />,
        children: [
            { path: 'login', element: <Lazy view={'Auth/Login'} /> },
            { path: 'forgot-password', element: <Lazy view={'Auth/ForgotPassword'} /> },
            { path: 'reset-password', element: <Lazy view={'Auth/ResetPassword'} /> },
            { path: 'register', element: <Lazy view={'Auth/Register'} /> },
        ],
    },
]

export default auth
