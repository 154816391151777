import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment/moment'
import { apiBulkoBagsList, apiBulkoBagsView } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/bulkoBags/getData', async datas => {
    const { data } = await apiBulkoBagsList(datas)
    return data
})

export const getView = createAsyncThunk('admin/bulkoBags/getView', async id => {
    if (!id) return viewData
    const { data } = await apiBulkoBagsView(id)
    data.type = '0'
    data.count = ''
    return data
})

const viewData = {
    collection_point_id: '',
    cage_id: '',
    name: '',
    capacity: '50',
    weight: '300',
    type: '0',
    count: '',
}

export const bulkoBagsSlice = createSlice({
    name: 'admin/bulkoBags',
    initialState: {
        loading: true,
        view: viewData,
        data: [],
        total: 0,
        table: {
            collection_point_id: '',
            start: moment().startOf('month').locale('en').format('YYYY-MM-DD'),
            end: moment().endOf('month').locale('en').format('YYYY-MM-DD'),
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
        setCollectionPoint: (state, { payload }) => {
            state.table.collection_point_id = payload
        },
        setDate: (state, { payload: { start, end } }) => {
            state.table.start = start
            state.table.end = end
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = viewData
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData, setCollectionPoint, setDate } = bulkoBagsSlice.actions

export default bulkoBagsSlice.reducer
