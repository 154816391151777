import { clone, remove } from 'lodash'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'

import { Button, RadioButton, Select } from 'Components/Form'
import { collectionPointStatus } from 'Utils/helper'

export const dataDefault = {
    collection_point_id: '',
    status: '0',
}

export default function CollectionPoint({ index, datas, data, setFieldValue, touched, errors, collectionPoints }) {
    var name = `collection_point[${index}].`

    return (
        <div className="flex flex-col gap-3">
            <div className="grid grid-cols-3 gap-3 sm:grid-cols-5">
                <Select
                    id={`${name}collection_point_id`}
                    name={`${name}collection_point_id`}
                    label={index === 0 ? 'Collection Point' : null}
                    placeholder="Collection Point"
                    value={data.collection_point_id}
                    options={collectionPoints.map(e => ({
                        id: e.id,
                        label: e.business_name,
                    }))}
                    onChange={e => setFieldValue(`${name}collection_point_id`, e.target.value)}
                    error={touched?.collection_point_id && errors?.collection_point_id}
                    className="col-span-3 sm:col-span-2"
                />
                <div className="col-span-3 sm:col-span-2">
                    <RadioButton
                        group
                        name={`${name}status`}
                        datas={collectionPointStatus()}
                        value={data.status}
                        label={index === 0 ? 'Status' : null}
                        className="grid grid-cols-3 gap-3"
                        error={touched?.status && errors?.status}
                    />
                </div>
                <div>
                    <div className={`flex items-center gap-3 ${index === 0 ? 'pt-7' : null}`}>
                        {index + 1 === datas.length ? (
                            <Button
                                type="button"
                                className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                onClick={() => {
                                    var saveTask = clone(datas)
                                    saveTask.push(dataDefault)
                                    setFieldValue('collection_point', saveTask)
                                }}
                            >
                                <AiOutlinePlus size={20} />
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                className="text-white border-danger bg-danger"
                                onClick={() => {
                                    var saveTask = clone(datas)
                                    remove(saveTask, (n, key) => key === index)
                                    setFieldValue('collection_point', saveTask)
                                }}
                            >
                                <AiOutlineClose size={20} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
