import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import useAuth from 'Utils/Hooks/useAuth'
import Sidebar from '../Sidebar'
import appRoutes from './appRoutes'
import Header from './Header'
import { useSelector } from 'react-redux'

export default function Admin({ guard }) {
    const location = useLocation()
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { loading, authenticated, loginCheck } = useAuth()
    const { user } = useSelector(state => state.auth)
    useEffect(() => {
        loginCheck(authenticated, guard, location.pathname)
        // eslint-disable-next-line
    }, [location.pathname, user])
    if (loading) return null
    return (
        <div className="dark:bg-graydark dark:text-gray/70">
            <div className="flex h-screen overflow-hidden">
                <Sidebar toUrl="/admin" appRoutes={appRoutes} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    <main>
                        <div className="p-4 mx-auto max-w-screen-2xl md:p-5 2xl:p-8">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
