import { useEffect, useState } from 'react'
import { HiSun, HiMoon } from 'react-icons/hi2'

const DarkModeSwitcher = () => {
    const colorTheme = localStorage.getItem('color-theme')
    const [colorMode, setColorMode] = useState(colorTheme === null ? 'light' : colorTheme)

    useEffect(() => {
        const className = 'dark'
        localStorage.setItem('color-theme', colorMode)
        if (colorMode === 'dark') {
            document.querySelector('body')?.classList.add(className)
        } else {
            document.querySelector('body')?.classList.remove(className)
        }
    }, [colorMode])

    return (
        <label className={`relative m-0 block h-6.5 w-11.5 rounded-full ${colorMode === 'dark' ? 'bg-secondary' : 'bg-stroke'}`}>
            <input
                type="checkbox"
                onChange={() => {
                    setColorMode(colorMode === 'light' ? 'dark' : 'light')
                }}
                className="absolute top-0 w-full h-full m-0 opacity-0 cursor-pointer dur"
            />
            <span
                className={`absolute top-1/2 left-[3px] flex h-5 w-5 -translate-y-1/2 translate-x-0 items-center justify-center rounded-full bg-white dark:bg-boxdark shadow-md dark:shadow-strokedark duration-75 ease-linear ${
                    colorMode === 'dark' && '!translate-x-full'
                }`}
            >
                <span className="dark:hidden">
                    <HiSun />
                </span>
                <span className="hidden dark:inline-block">
                    <HiMoon />
                </span>
            </span>
        </label>
    )
}

export default DarkModeSwitcher
