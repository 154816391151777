import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { apiCkeditor } from 'Services/AuthService'

function uploadAdapter(loader) {
    return {
        upload: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const image = await loader.file
                    apiCkeditor({
                        image: image,
                    })
                        .then(({ data }) => {
                            resolve({ default: data.image })
                        })
                        .catch(() => reject())
                } catch (error) {
                    reject()
                }
            })
        },
    }
}

function UploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return uploadAdapter(loader)
    }
}

export default function TextArea({ id = '', label = '', className = '', error = '', ...props }) {
    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div>
                <CKEditor
                    config={{
                        extraPlugins: [UploadPlugin],
                    }}
                    editor={ClassicEditor}
                    data={props.value}
                    onChange={(event, editor) => props.onChange(editor.getData())}
                    id={id}
                />
            </div>
            {error && <p className="text-sm text-danger">{error}</p>}
        </div>
    )
}
