import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dataDefault } from 'Components/User/BagRequests/BagRequests'
import { apiCollectorBagRequestsList, apiCollectorBagRequestsView } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/collectorBagRequests/getData', async datas => {
    const { data } = await apiCollectorBagRequestsList(datas)
    return data
})

export const getView = createAsyncThunk('admin/collectorBagRequests/getView', async id => {
    if (!id) return viewData
    const { data } = await apiCollectorBagRequestsView(id)
    data.material_type = data.material_type && data.material_type.length !== 0 ? data.material_type : [dataDefault]
    return data
})

const viewData = {
    collection_point_id: '',
    collector_id: '',
    material_type: [dataDefault],
}

export const collectorBagRequestsSlice = createSlice({
    name: 'admin/collectorBagRequests',
    initialState: {
        loading: true,
        view: viewData,
        data: [],
        total: 0,
        table: {
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = viewData
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData } = collectorBagRequestsSlice.actions

export default collectorBagRequestsSlice.reducer
