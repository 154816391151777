import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiContactsList, apiContactsView } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/contacts/getData', async datas => {
    const { data } = await apiContactsList(datas)
    return data
})

export const getView = createAsyncThunk('admin/contacts/getView', async id => {
    if (!id) return null
    const { data } = await apiContactsView(id)
    return data
})

export const contactsSlice = createSlice({
    name: 'admin/contacts',
    initialState: {
        loading: true,
        view: null,
        data: [],
        total: 0,
        table: {
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = null
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData } = contactsSlice.actions

export default contactsSlice.reducer
