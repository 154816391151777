const Cart = ({ title = null, subtitle = null, className = null, divclass = 'p-6', children }) => (
    <div className="flex flex-col items-center">
        <div
            className={`w-full xl:block rounded-sm border border-stroke bg-white shadow-lg dark:shadow-strokedark dark:border-strokedark dark:bg-boxdark ${className}`}
        >
            {title && (
                <div className="flex flex-row items-center justify-between gap-3 px-5 py-3 border-b lex border-stroke dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">{title}</h3>
                    {subtitle && subtitle}
                </div>
            )}
            <div className={divclass}>{children}</div>
        </div>
    </div>
)
export default Cart
