import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dataDefault } from 'Components/User/Pickups/BulkoBag'
import { apiPickupsList, apiPickupsView } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/pickups/getData', async datas => {
    const { data } = await apiPickupsList(datas)
    return data
})

export const getView = createAsyncThunk('admin/pickups/getView', async id => {
    if (!id) return viewData
    const { data } = await apiPickupsView(id)
    data.pickup_bulko_bag = data.pickup_bulko_bag && data.pickup_bulko_bag.length !== 0 ? data.pickup_bulko_bag : [dataDefault]
    return data
})

const viewData = {
    collection_point_id: '',
    name: '',
    pickup_bulko_bag: [dataDefault],
}

export const pickupsSlice = createSlice({
    name: 'admin/pickups',
    initialState: {
        loading: true,
        view: viewData,
        data: [],
        total: 0,
        table: {
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = viewData
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData } = pickupsSlice.actions

export default pickupsSlice.reducer
