const isNumString = str => !isNaN(Number(str))

export function deepParseJson(jsonString) {
    if (typeof jsonString === 'string') {
        if (isNumString(jsonString)) {
            return jsonString
        }
        try {
            return deepParseJson(JSON.parse(jsonString))
        } catch (err) {
            return jsonString
        }
    } else if (Array.isArray(jsonString)) {
        return jsonString.map(val => deepParseJson(val))
    } else if (typeof jsonString === 'object' && jsonString !== null) {
        return Object.keys(jsonString).reduce((obj, key) => {
            const val = jsonString[key]
            obj[key] = isNumString(val) ? val : deepParseJson(val)
            return obj
        }, {})
    } else {
        return jsonString
    }
}

export function buildFormData(form, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
            buildFormData(form, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        form.append(parentKey, data == null ? '' : data)
    }
}
