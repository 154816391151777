import { Field } from 'formik'
import { FiCheck } from 'react-icons/fi'

export default function Checkbox({ className = '', group = false, error = '', ...props }) {
    if (group) {
        return (
            <div>
                {props.label && (
                    <label className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                        {props.label}
                    </label>
                )}
                <div className={className}>
                    {props.datas.map((data, key) => (
                        <Checkbox
                            key={key}
                            id={data.id}
                            name={props.name}
                            value={data.value}
                            checked={props.value.includes(data.value)}
                            label={data.label}
                            message={data.message ?? ''}
                            disabled={props.disabled ?? false}
                        />
                    ))}
                </div>
                {error && <p className="text-sm text-danger">{error}</p>}
            </div>
        )
    }
    return (
        <div className={className}>
            <label
                htmlFor={props.id}
                className={`flex select-none items-center ${!props.disabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            >
                <div className="relative">
                    <Field type="checkbox" {...props} className="sr-only " />
                    <div
                        className={`${props.label ? 'mr-2' : ''} flex h-5 w-5 items-center justify-center rounded ${
                            props.checked && !props.disabled
                                ? `border-primary dark:border-secondary bg-gray dark:bg-graydark`
                                : ''
                        } ${props.disabled ? 'bg-gray dark:bg-graydark' : 'border'}`}
                    >
                        <span
                            className={`opacity-0 ${
                                props.checked ? `!opacity-100 ${!props.disabled ? 'text-primary dark:text-secondary' : ''}` : ''
                            } `}
                        >
                            <FiCheck size={14} />
                        </span>
                    </div>
                </div>
                {props.label}
            </label>
            {error && <p className="text-sm text-danger">{error}</p>}
        </div>
    )
}
