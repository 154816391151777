import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiContactsList, apiContactsView } from 'Services/UserService'

export const getData = createAsyncThunk('user/contacts/getData', async datas => {
    const { data } = await apiContactsList(datas)
    return data
})

export const getView = createAsyncThunk('user/contacts/getView', async id => {
    if (!id) return viewData
    const { data } = await apiContactsView(id)
    return data
})

const viewData = {
    subject: '',
    email: '',
    attachments: [],
    attachment_remove: [],
}

export const contactsSlice = createSlice({
    name: 'user/contacts',
    initialState: {
        loading: true,
        data: [],
        view: viewData,
        total: 0,
        table: {
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = viewData
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData } = contactsSlice.actions

export default contactsSlice.reducer
